import "./scss/app.scss";

$(".burgerMenu").on("click", function () {
  $(this).toggleClass("change");
  $(".linksBox").toggleClass("opened");
});

$(".whatisLink").on("click", function (e) {
  e.preventDefault();
  let elementPosition = document.getElementById("whatis").offsetTop;

  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $("html, body").animate(
      {
        scrollTop: $("#whatis").offset().top - 70,
      },
      500
    );
  } else {
    window.scrollTo({
      top: elementPosition - 70,
      behavior: "smooth",
    });
  }
});

$(".alertLink").on("click", function (e) {
  e.preventDefault();
  let elementPosition = document.getElementById("alert").offsetTop;

  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $("html, body").animate(
      {
        scrollTop: $("#alert").offset().top - 70,
      },
      500
    );
  } else {
    window.scrollTo({
      top: elementPosition - 70,
      behavior: "smooth",
    });
  }
});

$(".crisisLink").on("click", function (e) {
  e.preventDefault();
  let elementPosition = document.getElementById("crisis").offsetTop;

  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $("html, body").animate(
      {
        scrollTop: $("#crisis").offset().top - 70,
      },
      500
    );
  } else {
    window.scrollTo({
      top: elementPosition - 70,
      behavior: "smooth",
    });
  }
});

$(".whyLink").on("click", function (e) {
  e.preventDefault();
  let elementPosition = document.getElementById("why").offsetTop;

  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $("html, body").animate(
      {
        scrollTop: $("#why").offset().top - 70,
      },
      500
    );
  } else {
    window.scrollTo({
      top: elementPosition - 70,
      behavior: "smooth",
    });
  }
});

$(".contactLink").on("click", function (e) {
  e.preventDefault();
  let elementPosition = document.getElementById("contactBox").offsetTop;

  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $("html, body").animate(
      {
        scrollTop: $("#contactBox").offset().top - 70,
      },
      500
    );
  } else {
    window.scrollTo({
      top: elementPosition - 70,
      behavior: "smooth",
    });
  }
});

if (screen.width > 991) {
  $(window).scroll(function () {
    let windowScroll = $(window).scrollTop(),
      mainImageHeight = $(".mainImage").height(),
      menuHeight = $(".menu").height();

    if (windowScroll > mainImageHeight - menuHeight) {
      $(".menu").addClass("fixed");
    } else {
      $(".menu").removeClass("fixed");
    }
  });
}

$(window).scroll(function () {
  let whatisOff = $("#whatis").offset().top,
    alertOff = $("#alert").offset().top,
    crisisOff = $("#crisis").offset().top,
    whyOff = $("#why").offset().top,
    contactOff = $("#contactBox").offset().top,
    windowHeight = $(window).height() / 2,
    windowScroll = $(window).scrollTop();

  $(".linksBox a").removeClass("selected");

  if (
    windowScroll > whatisOff - windowHeight &&
    windowScroll < alertOff - windowHeight
  ) {
    $(".whatisLink").addClass("selected");
  } else if (
    windowScroll > alertOff - windowHeight &&
    windowScroll < crisisOff - windowHeight
  ) {
    $(".alertLink").addClass("selected");
  } else if (
    windowScroll > crisisOff - windowHeight &&
    windowScroll < whyOff - windowHeight
  ) {
    $(".crisisLink").addClass("selected");
  } else if (
    windowScroll > whyOff - windowHeight &&
    windowScroll < contactOff - windowHeight
  ) {
    $(".whyLink").addClass("selected");
  } else if (windowScroll > contactOff - windowHeight) {
    $(".contactLink").addClass("selected");
  }
});

if (screen.width < 992) {
  $(".linksBox a").on("click", function () {
    $(".linksBox").removeClass("opened");
    $(".burgerMenu").removeClass("change");
  });
}
